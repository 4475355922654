<template>
  <div>
    <br>
    <br>

    <div class="all-page-loader"
      v-if="isLoading">
      <div class="page-loader"></div>
    </div>

    

    <div class="container" style="background-color: #ffffff;padding: 20px;border-radius: 13px;"
      v-if="notifi.show == false || notifi.isClose">
      
      <div>
        <router-link :to="'/smed/'+$route.params.idEscola">Voltar para a lista</router-link>
      </div>
      <div>
        <span class="title is-4">
          Equipamento da: 
        </span>
        <span class="title is-3">
          {{this.escola.nome}}
        </span>
      </div>
      <br>
      <div>
        <div class="columns">
          <div class="column is-4">
            <div class="field">
              <label class="label">Tipo (Obrigátorio)</label>
              <div class="control">
                <div class="select" style="width: 100%;">
                  <select style="width: 100%;" v-model="equipamento.idTipo">
                    <option value="0">Selecione ...</option>
                    <option value="1">Gabinete</option>
                    <option value="2">Monitor</option>
                    <option value="3">Notebook</option>
                    <option value="10">
                      Computador (conjunto de equipamentos)
                    </option>
                    <option value="4">Impressora</option>
                    <option value="5">Roteador</option>
                    <option value="6">Switch</option>
                    <option value="7">Access Point</option>
                    <option value="8">Nobreaks</option>
                    <option value="9">Estabilizador</option>
                    <option value="11">Projetor</option>
                    <option value="12">Computador Embutido(projetor+computador)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-4">
             <div class="field">
                <span class="label"
                  v-show="equipamento.idTipo != 10">
                  Patrimônio (Obrigátorio)
                </span>
                <span class="label"
                  v-show="equipamento.idTipo == 10">
                  Patrimônio Gabinete (Obrigátorio)
                </span>
                <div class="control">
                  <input class="input" type="text" placeholder="Patrimônio" v-model="equipamento.patrimonio">
                </div>
              </div>
          </div>
        </div>
        
        <div v-show="equipamento.idTipo == 10 && isAdd">
          Você precisa salvar o computador antes de continuar: 
          <button class="button"
            @click="sendPost(1)">
            Salvar Computador
          </button>
        </div>

        <div>
          <span class="subtitle is-5"
            v-show="equipamento.idTipo == 10 && !isAdd">
            - Equipamentos Relacionados
          </span>
        </div>


        <div class="columns is-multiline"
          v-if="!isAdd"
          v-show="equipamento.idTipo == 10">

          <!-- <div class="column is-4">
            <div class="box">
              <div class="field">
                <span class="label">Patrimônio do Monitor</span>
                <div class="control">
                  <input class="input" type="text" 
                    placeholder="Patrimônio do Monitor" 
                    v-model="monitor.patrimonio">
                </div>
              </div>
              <div class="is-flex is-justify-content-flex-end">
                <button class="button is-success is-small" 
                  @click="clickBtnSaveMonitor">
                  Adicionar
                </button>
                <button class="button is-success is-small" 
                  @click="clickBtnSaveMonitor">
                  Salvar
                </button>
              </div>
            </div>
          </div> -->
          
          <!-- <div class="column is-4">
            <div class="box">
              <div class="field">
                <span class="label">Patrimônio Estabilizador</span>
                <div class="control">
                  <input class="input" type="text" placeholder="Patrimônio" v-model="equipamento.patrimonio">
                </div>
              </div>
              <div class="is-flex is-justify-content-flex-end">
                <button class="button is-success is-small">
                  Salvar
                </button>
              </div>
            </div>
          </div> -->

          <div class="column is-4"
            v-for="(equip, index) in equipamento.equipamentosAssociados" 
            :key="index">
            <div class="box"
              style="border: 1px solid #7cdf7c;">
              <div class="field">
                <span class="label">Patrimônio (Obrigátorio)</span>
                <div class="control">
                  <input class="input" type="text" 
                    placeholder="Patrimônio" 
                    v-model="equip.patrimonio">
                </div>
              </div>
              <div class="field">
                <label class="label">Tipo (Obrigátorio)</label>
                <div class="control">
                  <div class="select" style="width: 100%;">
                    <select style="width: 100%;" 
                      v-model="equip.idTipo">
                      <option value="0">Selecione ...</option>
                      <option value="2">Monitor</option>
                      <option value="8">Nobreaks (nobreaks)</option>
                      <option value="9">Estabilizador</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="is-flex is-justify-content-flex-end">
                <div class="is-flex is-justify-content-flex-end">
                  <div class="buttons">
                    <!-- <button class="button is-success is-small" 
                      @click="clickBtnSaveMonitor">
                      Adicionar
                    </button> -->
                    <button class="button is-danger is-small is-outlined" 
                      @click="clickRemoverEquipamentoAssiciado(equip)">
                      Remover
                    </button>
                    <button class="button is-success is-small" 
                      @click="clickBtnSalvarEquipAssociado(equip)">
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-4">
            <div class="box"
              v-show="!showAddEquipamentoAssociado">
              <button class="button is-link" 
                @click="showAddEquipamentoAssociado = true">
                + Adicionar Equipamento
              </button>
            </div>
            <div class="box" 
              v-show="showAddEquipamentoAssociado">
              <div class="field">
                <span class="label">Patrimônio</span>
                <div class="control">
                  <input class="input" type="text" 
                    placeholder="Patrimônio" 
                    v-model="newEquipAssociado.patrimonio">
                </div>
              </div>
              <div class="field">
                <label class="label">Tipo (Obrigátorio)</label>
                <div class="control">
                  <div class="select" style="width: 100%;">
                    <select style="width: 100%;" 
                      v-model="newEquipAssociado.idTipo">
                      <option value="0">Selecione ...</option>
                      <!-- <option value="1">Gabinete</option> -->
                      <option value="2">Monitor</option>
                      <!-- <option value="3">Notebook</option>
                      <option value="10">
                        Computador (conjunto de equipamentos)
                      </option>
                      <option value="4">Impressora</option>
                      <option value="5">Roteador</option>
                      <option value="6">Switch</option>
                      <option value="7">Access Point</option> -->
                      <option value="8">Nobreak</option>
                      <option value="9">Estabilizador</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="is-flex is-justify-content-flex-end">
                <div class="buttons">
                  <button class="button is-small"
                    @click="showAddEquipamentoAssociado = false">
                    Cancelar
                  </button>
                  <button class="button is-success is-small"
                    @click="clickBtnAddNewEquip">
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>



        <div>
          <div v-if="equipamento.associadoEm.length > 0">
            <span class="title is-6">
              Este equipamento esta associado em:</span>
          </div>
          <div class="columns is-multiline">
            <!-- <div class="column is-3"
              v-for="(equip, index) in equipamento.equipamentosAssociados" 
              :key="index">
              <div class="box">
                Patrimônio: {{equip.patrimonio}}
                <br>
                Tipo: 
                <span v-if="equip.idTipo == 1">Gabinete</span>
                <span v-else-if="equip.idTipo == 2">Monitor</span>
                <span v-else-if="equip.idTipo == 3">Notebook</span>
                <span v-else-if="equip.idTipo == 4">Impressora</span>
                <span v-else-if="equip.idTipo == 5">Roteador</span>
                <span v-else-if="equip.idTipo == 6">Switch</span>
                <span v-else-if="equip.idTipo == 7">Access Point</span>
                <span v-else-if="equip.idTipo == 8">Nobreaks</span>
                <span v-else-if="equip.idTipo == 9">Estabilizador</span>
                <span v-else>Tipo não cadastrado</span>
                <br>
                ID: {{equip.idEquipamento}}
                <div class="is-flex is-justify-content-flex-end">
                  <button class="button is-danger is-small"
                    @click="clickRemoverAssociacao(equip)">
                    Remover Associação
                  </button>
                </div>
              </div>
            </div> -->
            <div class="column is-3"
              v-for="(equip, index) in equipamento.associadoEm" 
              :key="index">
              <div class="box">
                Patrimônio: {{equip.patrimonio}}
                <br>
                Tipo: 
                <span v-if="equip.idTipo == 1">Gabinete</span>
                <span v-else-if="equip.idTipo == 2">Monitor</span>
                <span v-else-if="equip.idTipo == 3">Notebook</span>
                <span v-else-if="equip.idTipo == 4">Impressora</span>
                <span v-else-if="equip.idTipo == 5">Roteador</span>
                <span v-else-if="equip.idTipo == 6">Switch</span>
                <span v-else-if="equip.idTipo == 7">Access Point</span>
                <span v-else-if="equip.idTipo == 8">Nobreaks</span>
                <span v-else-if="equip.idTipo == 9">Estabilizador</span>
                <span v-else-if="equip.idTipo == 10">Computador</span>
                <span v-else>Tipo não cadastrado</span>
                <br>
                ID: {{equip.idEquipamento}}
                <div class="is-flex is-justify-content-flex-end">
                  *Você precisa ir no equipamento pai para remover Associação
                  <!-- <button class="button is-danger is-small"
                    @click="clickRemoverAssociacao(equip)">
                    Remover Associação
                  </button> -->
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <br>

        <!-- <div>
          equipamentosAssociados={{equipamento.equipamentosAssociados}}
        </div>
        <div>
          associadoEm={{equipamento.associadoEm}}
        </div> -->

        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Onde este equipamento esta localizado? (Obrigátorio)</label>
              <div class="control">
                <textarea class="textarea" placeholder="secretaria, almoxarifado ..." rows="1" 
                  v-model="equipamento.localizacao">
                </textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="control">
              <label class="label">Equipamento esta em operação? (Obrigátorio)</label>
              <label class="radio" style="margin-right: 21px;">
                <input type="radio" id="radio1" value="1" v-model="equipamento.isOperante">
                SIM
              </label>
              <br>
              <label class="radio" style="margin-right: 21px;">
                <input type="radio" id="radio1" value="2" v-model="equipamento.isOperante">
                NÃO
              </label>
              <br>
              <label class="radio">
                <input type="radio" id="radio1" value="3" v-model="equipamento.isOperante">
                SIM, mas com problema
              </label>
            </div>
          </div>
        </div>

        <div class="columns" v-if="equipamento.isOperante >= 2">
          <div class="column">
            <div class="control">
              <label class="label">
                Você precisa da ajuda de um tecnico pra infoarmar o problema?
                Ou você esta apto a informar? (Obrigátorio)
              </label>
              <label class="radio">
                <input type="radio" id="radio2" value="0" v-model="equipamento.isApto">
                Preciso de ajuda
              </label>
              <br>
              <label class="radio">
                <input type="radio" id="radio2" value="1" v-model="equipamento.isApto">
                Estou apto a informar. Pois já foi verificado ou tenho conhecimento.
              </label>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div v-if="equipamento.isOperante >= 2">
              <div class="field">
                <label class="label">Informe o que puder do problema</label>
                <div class="control">
                  <textarea class="textarea" placeholder="Probelma ..." v-model="equipamento.problema">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <div
        v-if="notifi.show">
        <!-- <div>
          <router-link :to="'/smed/'+$route.params.idEscola">Voltar para a lista</router-link>
        </div> -->
        <div class="notification is-danger">
          <button class="delete" v-if="notifi.isClose" @click="clickCloseNotifi"></button>
          {{notifi.msg}}
        </div>
      </div>

      <hr>

      <div>
        <button class="button is-link"
          @click="clickBtnEnviar">
          Enviar
        </button>
        <button class="button is-danger"
          style="float: right;"
          v-show="!isAdd"
          @click="isModal = true">
          Deletar
        </button>
      </div>
      

    </div>

    <!-- MODAL1 -->
    <div class="modal"
      :class="{'is-active': this.isModal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirmação</p>
          <button class="delete" aria-label="close"
            @click="isModal = false">
          </button>
        </header>
        <section class="modal-card-body">
          
          <span class="title is-4">Deseja continuar com esta operação?</span>

        </section>
        <footer class="modal-card-foot">
          <div style="width: 100%;">
            <button class="button is-success"
              style="float: right;"
              @click="clickConfirmDeletar">
              Confirmar
            </button>
            <button class="button"
              @click="isModal = false">
              Cancel
            </button>
          </div>
        </footer>
      </div>
    </div>


    <!-- MODAL2 -->
    <div class="modal"
      :class="{'is-active': this.isModal2}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Relacionar equipamento</p>
          <button class="delete" aria-label="close"
            @click="isModal2 = false">
          </button>
        </header>
        <section class="modal-card-body">
          
          <div>
            <div class="field has-addons">
              <div class="control">
                <input class="input" type="text" placeholder="Patrimonio"
                  v-model="strBuscaEquipamento"
                 @keyup.enter="buscaEquipamento">
              </div>
              <div class="control">
                <a class="button is-info"
                 @click="buscaEquipamento">
                  Buscar
                </a>
              </div>
            </div>
            <div>
              <div>
                <article class="panel is-primary"
                  v-for="(equip, index) in listBuscaEquipamentos" 
                  :key="index"
                  @click="clickItemBuscaEquipamento(equip)">
                  <a class="panel-block">
                    Patrimônio: {{equip.patrimonio}} | Tipo:
                    <span v-if="equip.idTipo == 1">Gabinete</span>
                    <span v-else-if="equip.idTipo == 2">Monitor</span>
                    <span v-else-if="equip.idTipo == 3">Notebook</span>
                    <span v-else-if="equip.idTipo == 4">Impressora</span>
                    <span v-else-if="equip.idTipo == 5">Roteador</span>
                    <span v-else-if="equip.idTipo == 6">Switch</span>
                    <span v-else-if="equip.idTipo == 7">Access Point</span>
                    <span v-else-if="equip.idTipo == 8">Nobreaks</span>
                    <span v-else-if="equip.idTipo == 9">Estabilizador</span>
                    <span v-else>Tipo não cadastrado</span>
                  </a>
                </article>
              </div>

            </div>
          </div>
          

        </section>
        <footer class="modal-card-foot">
          <div style="width: 100%;">
            <button class="button is-success"
              style="float: right;">
              Confirmar
            </button>
            <button class="button"
              @click="isModal2 = false">
              Cancel
            </button>
          </div>
        </footer>
      </div>
    </div>

  </div>
</template>

<script>
  import Request from '@/utils/Request.js';

  export default {
    
    data() {
      return {
        showAddEquipamentoAssociado: false, 
        newEquipAssociado: {
          idTipo: 0
        },
        strBuscaEquipamento: '',
        listBuscaEquipamentos: [],
        isModal: false,
        isModal2: false,
        isLoading: false,
        notifi: {
          isClose: false,
          show: false,
          type: 'danger',
          msg: ''
        },
        isAdd: true,
        escola: {},
        monitor: {},
        equipamento: {
          associadoEm: [],
          idTipo: 0,
          isOperante: 1,
          isApto: '0'
        }
      }
    },
    
    mounted() {
      let idEscola = this.getUrlIdEscola();
      this.sendGetEscolaByID(idEscola);
    },

    methods: {
      clickRemoverEquipamentoAssiciado(equip){
        this.isLoading = true;
        const success = (response) => {
          this.isLoading = false;
          this.sendDeleteEquipAssociado(equip.idEquipamento);
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Contate os responsaveis';
        }
        let idEquipamento = this.getUrlIdEquipamento();
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento+'/remove/'+equip.idEquipamento);
        Request.delete(url)
          .then(success)
          .catch(error);
      },
      clickItemBuscaEquipamento(equip){
        this.sendPostAddEquipamento(equip);
      },
      sendPostAddEquipamento(equip){
        this.isLoading = true;
        let equipamento = Object.assign({}, equip);
        const success = (response) => {
          this.isLoading = false;
          let idEscola = this.getUrlIdEscola();
          this.sendGetEscolaByID(idEscola);
          this.isModal2 = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Por favor contate os responsaveis';
        }
        let idEquipamento = this.getUrlIdEquipamento();
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento+'/add');
        let data = {
          idEquipamento: equip.idEquipamento
        }
        Request.post(url, data)
          .then(success)
          .catch(error);
      },
      buscaEquipamento(){
        this.sendBuscaEquipamento(this.strBuscaEquipamento)
      },
      sendBuscaEquipamento(str){
        this.isLoading = true;
        const success = (response) => {
          this.listBuscaEquipamentos = response.data.list;
          this.listBuscaEquipamentos = this.listBuscaEquipamentos.filter( (item) => {
            return this.equipamento.idEquipamento != item.idEquipamento;
          })
          this.isLoading = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          // this.notifi.show = true;
          // this.notifi.isClose = false;
          // this.notifi.type = 'danger';
          // this.notifi.msg = 'Local não encontrado. Verifique se o endereço da URL esta correto';
        }
        const url = Request.mountUrl2('/equipamentos/?_limit=10&_orderBy=idEquipamento&_order=desc&patrimonio='+str);
        Request.get(url)
          .then(success)
          .catch(error);
      },
      clickBtnEnviar(){
        if(this.isAdd){
          this.sendPost();
        }
        else{
          this.sendPut();
        }
      },
      getUrlIdEquipamento(){
        return this.$route.params.idEquipamento;
      },
      getUrlIdEscola(){
        return this.$route.params.idEscola;
      },
      sendGetEscolaByID(idEscola){
        this.isLoading = true;
        const success = (response) => {
          this.escola = response.data;
          this.isLoading = false;
          let idEquipamento = this.getUrlIdEquipamento();
          if (idEquipamento != undefined) {
            this.isAdd = false;
            this.sendGetEquipamentoById(idEquipamento);
          }
          else{
            this.isAdd = true;
          }
        }
        const error = (error) => {
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = false;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Local não encontrado. Verifique se o endereço da URL esta correto';
        }
        const url = Request.mountUrl2('/escolas/'+idEscola);
        Request.get(url)
          .then(success)
          .catch(error);
      },
      sendGetEquipamentoById(idEquipamento){
        this.isLoading = true;
        const success = (response) => {
          console.log(response);
          this.equipamento = response.data.list[0];
          console.log(this.equipamento.idEscola);
          console.log(this.getUrlIdEscola());
          if(this.equipamento.idEscola != this.getUrlIdEscola()){
            this.$router.push('/smed/'+this.getUrlIdEscola());
          }
          else if(this.equipamento.associadoEm == undefined){
            this.equipamento.associadoEm = [];
          }
          this.isLoading = false;
        }
        const error = (error) => {
          this.isLoading = false;
          console.log(error);
          this.notifi.show = true;
          this.notifi.isClose = false;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro ao carregar equipamento';
        }
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.get(url)
          .then(success)
          .catch(error);
      },
      sendPost(isRedirect=0){
        this.isLoading = true;
        let equipamento = Object.assign({}, this.equipamento);
        equipamento.idEscola = this.escola.idEscola;
        delete equipamento.associadoEm;
        if(equipamento.isOperante == 1){
          equipamento.isApto = '0';
        }
        const success = (response) => {
          if (isRedirect == 1) {
            let urlRedirect = '/smed/'+this.getUrlIdEscola()+'/equipamento/'+response.data.idEquipamento;
            this.$router.push(urlRedirect);
            let idEscola = this.getUrlIdEscola();
            this.sendGetEscolaByID(idEscola);
          }
          else{
            this.$router.push('/smed/'+this.getUrlIdEscola());
          }
          this.isLoading = false;
          this.notifi.show = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Verifique se os campos foram preencidos corretamente';
        }
        const url = Request.mountUrl2('/equipamentos');
        Request.post(url, equipamento)
          .then(success)
          .catch(error);
      },
      sendPut(){
        const success = (response) => {
          // this.equipamento = {};
          this.$router.push('/smed/'+this.getUrlIdEscola());
        }
        const error = (error) => {
          console.log(error);
        }
        let equipamento = Object.assign({}, this.equipamento);
        let idEquipamento = equipamento.idEquipamento;
        delete equipamento.idEquipamento;
        delete equipamento.equipamentosAssociados;
        delete equipamento.associadoEm;
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.put(url, equipamento)
          .then(success)
          .catch(error);
      },
      clickBtnAddNewEquip(){
        this.newEquipAssociado.idEscola = this.getUrlIdEscola();
        this.sendPostEquipamentoAssociado(this.newEquipAssociado)
      },
      sendPostEquipamentoAssociado(equipamento){
        this.isLoading = true;
        equipamento = Object.assign({}, equipamento);
        equipamento.isOperante = 1;
        // equipamento.isApto = '0';
        const success = (response) => {
          this.newEquipAssociado = {
            idTipo: 0
          }
          this.sendPostAddEquipamento(response.data)
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Verifique se os campos foram preencidos corretamente';
        }
        const url = Request.mountUrl2('/equipamentos');
        Request.post(url, equipamento)
          .then(success)
          .catch(error);
      },
      clickCloseNotifi(){
        this.notifi.close = false;
        this.notifi.show = false;
      },
      clickConfirmDeletar(){
        let idEquipamento = this.getUrlIdEquipamento();
        this.sendDeleteEquip(idEquipamento);
      },
      sendDeleteEquip(idEquipamento){
        this.isLoading = true;
        const success = (response) => {
          this.isLoading = false;
          this.$router.push('/smed/'+this.getUrlIdEscola());
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Contate os responsaveis';
        }
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.delete(url)
          .then(success)
          .catch(error);
      },

      sendDeleteEquipAssociado(idEquipamento){
        this.isLoading = true;
        const success = (response) => {
          this.isLoading = false;
          let idEscola = this.getUrlIdEscola();
          this.sendGetEscolaByID(idEscola);
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Contate os responsaveis';
        }
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.delete(url)
          .then(success)
          .catch(error);
      },
      clickBtnSalvarEquipAssociado(equip){
        this.isLoading = true;
        const success = (response) => {
          let idEscola = this.getUrlIdEscola();
          this.sendGetEscolaByID(idEscola);
          // this.isLoading = false;
        }
        const error = (error) => {
          console.log(error);
          this.isLoading = false;
          this.notifi.show = true;
          this.notifi.isClose = true;
          this.notifi.type = 'danger';
          this.notifi.msg = 'Erro. Contate os responsaveis';
        }
        let equipamento = Object.assign({}, equip);
        let idEquipamento = equipamento.idEquipamento;
        delete equipamento.idEquipamento;
        delete equipamento.equipamentosAssociados;
        delete equipamento.associadoEm;
        const url = Request.mountUrl2('/equipamentos/'+idEquipamento);
        Request.put(url, equipamento)
          .then(success)
          .catch(error);
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>